import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { addWatchList } from '../services/Api'

const WatchList: React.FC = () => {
  const submit = (e:any) => {
    const target = e.target
    const email = target.email.value
    addWatchList( email )
    .then( ( result ) => {
      const data = result.data.data
      const error = result.data.error
      const msg = data || error
      alert( msg )
    } ).catch( ( error ) => console.error( error ) )
    e.preventDefault()
  }
  return (
    <div
      className='watch-list'
      >
      <form
        onSubmit={ submit }
        >
        <p>
          This site is currently not live. If you would like to be notified when it is please input your email here:
        </p>
        <input
          type='email'
          name='email'
          />
        <input
          type='submit'
          value='send'
          />
      </form>
    </div>
  )
}

export default WatchList
