import React, { useState, createContext, useContext } from "react"
import UserData from '../types/User.type'

type UserContextType = {
  user: UserData | null
  setUser: React.Dispatch<React.SetStateAction<UserData | null>>
}

type UserContextProviderProps = {
  children: React.ReactNode
}

export const UserContext = createContext( {} as UserContextType )

export const UserContextProvider = ( { children }: UserContextProviderProps ) => {
  const [ user, setUser ] = useState<UserData | null>( null )
  
  return (
    <UserContext.Provider value={ { user, setUser } }>
      { children }
    </UserContext.Provider>
  )
}
