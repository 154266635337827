import React, { useState, createContext, useContext } from "react"
import CharityData from '../types/Charity.type'

type CharityContextType = {
  charity: CharityData | null
  setCharity: React.Dispatch<React.SetStateAction<CharityData | null>>
}

type CharityContextProviderProps = {
  children: React.ReactNode
}

export const CharityContext = createContext( {} as CharityContextType )

export const CharityContextProvider = ( { children }: CharityContextProviderProps ) => {
  const [ charity, setCharity ] = useState<CharityData | null>( null )
  
  return (
    <CharityContext.Provider value={ { charity, setCharity } }>
      { children }
    </CharityContext.Provider>
  )
}

