import React from 'react'
import './styles/reset.css'
import AppRouter from './AppRouter'
import Footer from './components/Footer.component'
import WatchList from './components/WatchList.component'
import Intro from './components/Intro.component'
import './styles/App.scss'
import CharityDetail from './components/Charity.detail.component'
import { CharityContextProvider } from './context/CharityContext'
import { UserContextProvider } from './context/UserContext'
import { CartContextProvider } from './context/CartContext'
import { ErrorContextProvider } from './context/ErrorContext'

const App: React.FC = () => {
  return (
    <div >
      <ErrorContextProvider>
        <CartContextProvider>
          <UserContextProvider >
            <CharityContextProvider >
              <WatchList />
              <Intro />
              <CharityDetail />
              <AppRouter />
              <Footer />
            </CharityContextProvider>
          </UserContextProvider >
        </CartContextProvider>
      </ErrorContextProvider>
    </div>
  )
}

export default App
