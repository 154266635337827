import React, { useContext } from 'react'
import { Navigate } from "react-router-dom"
import UseLocalStorage from '../hooks/UseLocalStorage'
import { UserContext } from '../context/UserContext'

const ProtectedRoute: React.FC<any> = ( { children } ) => {
  const userContext = useContext( UserContext )
  const now = new Date().getTime()
  if( !userContext.user || userContext.user.expires < now  ) {
    return <Navigate to='/' />
  } else {
    return children
  }
}

export default ProtectedRoute


