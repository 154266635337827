import React, { useState, createContext, useContext } from "react"
import ErrorData from '../types/Error.type'

type ErrorContextType = {
  error: ErrorData | null
  setError: React.Dispatch<React.SetStateAction<ErrorData | null>>
}

type ErrorContextProviderProps = {
  children: React.ReactNode
}

export const ErrorContext = createContext( {} as ErrorContextType )

export const ErrorContextProvider = ( { children }: ErrorContextProviderProps ) => {
  const [ error, setError ] = useState<ErrorData | null>( null )
  
  return (
    <ErrorContext.Provider value={ { error, setError } }>
      { children }
    </ErrorContext.Provider>
  )
}
