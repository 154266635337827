import { useCart } from '../context/CartContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'

type cartProps = {
  isOpen: boolean,
}

const Cart = ( { isOpen }: cartProps ) => {
  const { closeCart, openCart, cart, addToCart, removeFromCart, cartTotal } = useCart()
  return <>
    <div
      className={ `cart ${ isOpen ? "cart-open" : "cart-closed" }` }
      >
      <FontAwesomeIcon
        className='cart-close'
        icon={ solid('times') }
        onClick={ closeCart }
        />
      <div
        className='cart-items'
        >
        <p
          className='cart-total'
          >
          Cart total: £{ cartTotal }
        </p>
        { cart.map( item => ( 
          <div
            className='cart-item'
            key={ item.id }
            >
            <div 
              className='cart-item-image'
              style={ { backgroundImage: `url(https://www.google.com/s2/favicons?sz=256&domain=${ item.image?.replace('http://', '') })` } } >
            </div>
            <div className='cart-item-details'>
              <span className='cart-item-name'>
                { item.name }
              </span>
              <span className='cart-item-price'>
                <span>£{ item.donation }</span>
                <span>{ item.subscription ? ' per month' : ' one time donation' }</span>
              </span>
            </div>
            <FontAwesomeIcon
              className='cart-item-remove'
              icon={ solid('times') }
              onClick={ () => removeFromCart( item.id ) }
              />
          </div>
          )
        ) }
        </div>
    </div>
  </>
}

export default Cart
