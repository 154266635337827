import { http, postCodeApi } from '../http-common'
import CharityData from '../types/Charity.type'
import UserData from '../types/User.type'
import authHeader from '../services/Auth-header'

function getCharities() {
  return http.get<Array<CharityData>>('charity')
}

function getCharityByName( name: string ) {
  return http.get<Array<CharityData>>('charity?name=' + name)
}

function getCharityByNumber( number: string ) {
  return http.get<Array<CharityData>>('charity?name=' + number)
}

function getCharityByLocation( location: string ) {
  return http.get<Array<CharityData>>('charity?name=' + location)
}

function getCharitiesByKeyword( keyword: string ) {
  return http.get<Array<CharityData>>('charity?search=' + keyword)
}

// Auth services
function userRegister( fname:string, lname:string, email:string, password:string ) {
  const data = {
    fname:fname,
    lname:lname,
    email:email,
    password:password
  }
  return http.request( {
    url:'auth/register',
    method:'POST',
    data: JSON.stringify( data )
  } )
}

function userAuth( email: string, password: string ) {
  const data = {
    email:email,
    password:password
  }
  return http.request( {
    url:'auth',
    method:'POST',
    data: JSON.stringify( data )
  })
}

function getProfile() {
  const header:any = authHeader()
  return http.request( {
    url:'user/profile',
    method:'GET',
    headers: header
  })
}

function addWatchList( email:string ) {
  const data = {
    email:email
  }
  return http.request( {
    url:'user/watch-list',
    method:'POST',
    data: JSON.stringify( data )
  } )
}

// Stripe services
function getClientSecret( amount: number ) {
  const data = {
    amount:amount
  }
  return http.request( {
    url:'stripe',
    method:'POST',
    data: JSON.stringify( data )
  } )
}

//Postcode service
function getPostCodeData( postcode: string ) {
  return postCodeApi.get('' + postcode)
}

export { getCharities, getCharityByName, getCharityByNumber, getCharityByLocation, getCharitiesByKeyword, getPostCodeData, userAuth, userRegister , getProfile, getClientSecret, addWatchList
}
