import axios from 'axios'

const http = axios.create( {
  //baseURL:
  //"https://cortfmaote.execute-api.eu-west-2.amazonaws.com/dev/",//Decode AWS
  //server
  baseURL: "https://4jj5dcsfci.execute-api.eu-west-2.amazonaws.com/dev/",//GGR AWS server
  //baseURL: "http://localhost:8080",
  //baseURL: "http://api.test.co.uk:8080",
  headers: {
    "Content-type": "application/json",
  }
  , withCredentials: false
} )

const postCodeApi = axios.create( {
  baseURL: "https://api.postcodes.io/postcodes/",
  headers: {
    "Content-type": "application/json"
  }
} )

export { http, postCodeApi }
