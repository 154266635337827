import React, { useState } from 'react'
import UseLocalStorage from '../hooks/UseLocalStorage'

const Intro: React.FC = () => {
  const [ accepted, setAccepted ] = UseLocalStorage( 'ggrIntro', null )
  const [ animateIntro, setAnimateIntro ] = useState( false )

  const Accept = ( event:any ) => {
    setAnimateIntro( true )
    setTimeout( () => setAccepted( 'true' ), 2000)
  }

  return (
    <div className='intro-wrapper'>
      { accepted != 'true' && (
        <div className={ `intro ${ animateIntro ? "hideIntro" : ""}` }>
          <div className='text'>
            <div
              className='logo'
              />
            <p>
              We believe that when you give to a charity, you should get something worthwhile back.
            </p>
            <p>
              …Other than just that warm fuzzy feeling
            </p>
            <button
              onClick={ Accept }
              >
              Let me Give and Get
            </button>
            <p
              className='fly'
              >
              By continuing you are accepting our <a href=''>cookie policy</a>
            </p>
            <div className='avios' >
            </div>
          </div>
        </div>
      ) }
    </div>
  )
}

export default Intro
