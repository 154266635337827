import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Loading from './components/Loading.component'
import ProtectedRoute from './utils/ProtectedRoute'
const Home = React.lazy( () => import( './pages/Home.page' ) )
const About = React.lazy( () => import( './pages/About.page' ) )
const Features = React.lazy( () => import( './pages/Features.page' ) )
const Contact = React.lazy( () => import( './pages/Contact.page' ) )
const Pricing = React.lazy( () => import( './pages/Pricing.page' ) )
const Auth = React.lazy( () => import( './pages/Auth.page' ) )
const Register = React.lazy( () => import( './pages/Register.page' ) )
const Help = React.lazy( () => import( './pages/Help.page' ) )
const Profile = React.lazy( () => import( './pages/Profile.page' ) )
const Error = React.lazy( () => import( './pages/Error.page' ) )

const AppRouter: React.FC<any> = () => {
  return (
    <React.Suspense fallback={ <Loading /> } >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/features' element={<Features />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/register' element={<Register />} />
        <Route path='/help' element={ <Help /> } />
        <Route path='/profile' element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        } />
        <Route path='*' element={<Error />} />
      </Routes>
    </React.Suspense>
  )
}

export default AppRouter
