import React from 'react'

const Loading: React.FC = () => {
  return (
    <p
      className="loading">
      Loading...
    </p>
  )
}

export default Loading
