import React, { useState, createContext, useContext } from "react"
import CartItem from "../types/Cart.type"
import Cart from "../components/Cart.component"

type CartContextType = {
  openCart: () => void
  closeCart: () => void
  addToCart: ( id: string, name: string, image: string, donation: number, subscription: boolean ) => void
  removeFromCart: ( id: string ) => void
  cartQty: number
  cartTotal: any
  cart: CartItem[]
  isInCart: ( id: string ) => boolean
}

const CartContext = createContext( {} as CartContextType )

type CartContextProviderProps = {
  children: React.ReactNode
}

export const useCart = () => useContext( CartContext )

export const CartContextProvider = ( { children }: CartContextProviderProps ) => {
  const [ cart, setCart ] = useState<CartItem[]>( [] ) 
  const [ isOpen, setIsOpen ] = useState( false )

  const cartQty = cart.length

  const cartTotal = cart.reduce( ( sum, { donation } ) => sum + donation*1, 0 )

  const addToCart = ( id: string, name: string, image: string, donation: number, subscription: boolean ) => {
    setCart( currentItems => { 
      return currentItems.find( item => item.id === id ) == null 
      ? [ ...currentItems, { id, donation, image, name, subscription } ]
      : currentItems.map( item => item.id === id ? { ...item, donation, subscription } : item )
    } )
  }

  const removeFromCart = ( id: string ) => {
    setCart( currentItems => currentItems.filter( item => item.id !== id ) )
  }

  const openCart = () => {
    setIsOpen( true )
  }

  const closeCart = () => {
    setIsOpen( false )
  }

  return (
    <CartContext.Provider value={ { 
      openCart,
      closeCart,
      addToCart, 
      removeFromCart,
      cartQty,
      cartTotal,
      cart,
      isInCart: ( id: string ) => {
        return cart.some( item => item.id === id )
      }
    } } >
      { children }
      <Cart isOpen={ isOpen } />
    </CartContext.Provider>
  )
}
